.picker {
    border: 1px solid #6f6f6f;
    border-style: dashed;
    padding: 20px;
    border-radius: 5px;
    width: 100%;
    cursor: pointer;
  }
  
  .container-drop {
    max-width: 100%;
    margin: 20px auto;
    .zone {
        border-radius: 5px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        border: 1px solid $primary;
        border-style: dashed;
        &:hover{
          background-color: darken($primarypastel, 10%);
        }
      }
      
      .drop-zone {
        width: 100%;
        height: 100%;
        cursor: pointer;
      }
      
      .drag-files,
      .drop-files {
        border-radius: inherit;
        width: 100%;
        height: 140px;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: background-color 300ms;
      }
      
      .selected {
        width: 100%;
        margin: auto;
        text-align: center;
        height: auto;
        background-color: $primarypastel;
        padding: 8%;
        small{
          font-size: 12px;
          
        }

      }
      
      .actions {
        // position: absolute;
        margin-top: 20px;
        display: flex;
        justify-content: center;
        padding: 10px;
        background-color: color-mix(in srgb, white, transparent 90%);
        right: 0;
        bottom: 0;
        width: 100%;
        gap: 10px;
        font-size: 0.7em;
        backdrop-filter: blur(1px);
      }
      
      .actions button {
        border-radius: 3px;
        border: 1px solid transparent;
        font-size: 1em;
        font-weight: 500;
        font-family: inherit;
        cursor: pointer;
        transition: border-color 0.25s;
        padding: 10px 35px;
      }
      
      .uploaded-txt {
        background-color: rgb(34, 116, 135);
        padding: 10px;
        border-radius: 3px;
      }
      
      .upload-btn {
        background-color: $green;
        &:hover {
          background-color: darken($green, 10%);
        }
       
      }
      
      .cancel-btn {
        border: 1px solid $red !important;
        background-color: transparent;
        color: $red;
        transition: background-color 0.5s;
        &:hover{
          background-color: $red;
          color: white;
        }
      }
      
      .drop-files {
        background-color: $primary;
        color: #545353;
      }
      
      img {
        width: 100%;
        display: block;
        border-radius: inherit;
      }
      
      .uploaded-url {
        display: block;
        width: 100%;
        font-size: 1em;
        word-wrap: break-word;
        margin: 10px 0;
        color: orange;
        text-decoration: underline;
      }
  }
  
  .cancel-btn {
    border: 1px solid $red !important;
    background-color: transparent;
    color: $red;
    transition: background-color 0.5s;
    &:hover{
      background-color: $red;
      color: white;
    }
  }